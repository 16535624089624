import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function Home() {
  return (
    <div className="container relative p-1 mx-auto min-w-full">
      <div className="gradient left-[25%] top-[33%] bg-[orange]" />
      <div className="gradient2 right-[25%] top-[23%] bg-[red]" />
      <div className="h-[90vh] sm:h-[95vh] flex flex-col sm:mt-8">
        <div className="grow flex flex-col items-center justify-center sm:my-32">
          <div className="delay-500 animate-slidein300 my-12">
            <div className="text-5xl font-bold">William Brown</div>
            <div className="text-2xl text-center">Software Developer</div>
          </div>
          <div className="flex sm:flex-col gap-6 sm:gap-0 delay-500 animate-slidein500">
            <a
              href="mailto:willbrown.co@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-[darkblue] group text-white p-3 px-6 font-bold shadow-xl mt-5 flex gap-3"
            >
              <FontAwesomeIcon icon={faEnvelope} size="xl" />
              <div className="group-hover:scale-105">Email</div>
            </a>
            <a
              href="https://www.linkedin.com/in/williamjamesbrown/"
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-[#0077B5] group text-white p-3 px-6 font-bold shadow-xl mt-5 flex gap-3"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl" />
              <div className="group-hover:scale-105">LinkedIn</div>
            </a>
            <a
              href="https://github.com/WJB3003/"
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-black group text-white p-3 px-6 font-bold shadow-xl mt-5 flex gap-3"
            >
              <FontAwesomeIcon icon={faGithub} size="xl" />
              <div className="group-hover:scale-105">Github</div>
            </a>
          </div>
        </div>
        <div className="py-8 flex flex-wrap gap-8 self-center justify-center delay-500 animate-slidein700">
          {[
            {
              name: "Typescript",
              icon: "/tech/icon/typescript.png",
            },
            {
              name: "Javascript",
              icon: "/tech/icon/javascript.png",
            },
            {
              name: "React",
              icon: "/tech/icon/react.png",
            },
            {
              name: "PostgreSQL",
              icon: "/tech/icon/postgresql.png",
            },
            {
              name: "Github",
              icon: "/tech/icon/github.png",
            },
            {
              name: "AWS",
              icon: "/tech/icon/aws.png",
            },
            {
              name: "React Native",
              icon: "/tech/icon/react-native.png",
            },
            {
              name: "Jest",
              icon: "/tech/icon/jest.png",
            },
            {
              name: "PHP",
              icon: "/tech/icon/php.png",
            },
          ].map((tech) => (
            <div key={tech.icon} className="flex flex-col items-center gap-2">
              <img alt={tech.icon} src={tech.icon} height={56} width={56} />
              <div className="font-bold">{tech.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
