import React from "react";
import "./../index.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../Components/Home";
import Project from "../Components/Project";
import Resume from "../Components/Resume";
import Work from "../Components/Work";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/resume",
    element: <Resume />,
  },
  {
    path: "/my-work",
    element: <Work />,
  },
  {
    path: "/project/:name",
    element: <Project />,
  },
]);

export default function App() {
  return (
    <>
      <RouterProvider router={router} />
      {window.location.pathname !== "/resume" && (
        <div className="absolute top-8 flex gap-3 w-[100vw] content-center">
          <a
            href="/"
            className="ml-8 sm:ml-4 mt-2 content-center text-xl font-bold"
          >
            William Brown
          </a>
          <div className="absolute end-8 sm:end-2 flex gap-3 sm:gap-2">
            <a
              href="/my-work"
              className="content-center font-bold hover:scale-105"
            >
              My Work
            </a>
            <a
              className="rounded-full bg-[darkblue] group text-white p-3 px-6 font-bold shadow-lg"
              href="/resume"
              target="_blank"
            >
              <div className="group-hover:scale-105">Resume</div>
            </a>
          </div>
        </div>
      )}
    </>
  );
}
