import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./index.css";

export default function Work() {
  const projects = [
    {
      name: "Travel Itinerary Web Application",
      description:
        "AI assisted travel itinerary planner that helps users plan their trips by providing recommendations on places to visit, eat, and stay.",
      image: "/projects/william-travel-home-screen.png",
      tech: [
        "React",
        "Nextjs",
        "Typescript",
        "Javascript",
        "Vercel",
        "Jest",
        "Zod",
        "Tailwind",
        "ESLint",
        "Prettier",
        "AWS",
        "Postgres",
      ],
      mermaid: `
        flowchart TD
          A[PostgreSQL] -->|Prisma| B[Nextjs]
          E[AWS S3] -->|Cloudfront| B
          I[Gemini] --> B
          B --> C[React]
          J[User Input] -->|Zod| C
          C -->|Vercel| H[william.travel]
          NextAuth --> B
        `,
      demo_link: "https://www.william.travel/",
      github_link: "https://github.com/WJB3003/next.itinerary.index",
    },
    // {
    //   name: "Fit Check - Be Real Clone",
    //   description:
    //     "App to allow users to take a photo of themselves and see how they would look like with different outfits.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: [
    //     "React Native",
    //     "Typescript",
    //     "Javascript",
    //     "App Center",
    //     "Jest",
    //     "Zod",
    //     "Tailwind",
    //     "ESLint",
    //     "Prettier",
    //     "AWS",
    //     "Postgres",
    //   ],
    //   demo_link: "",
    //   github_link: "https://github.com/WJB3003/fitCheck",
    // },
    // {
    //   name: "Guessing Game",
    //   description:
    //     "Generic guessing game that allows users to guess a image 3D files or text to create games similar to Wordle.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: [
    //     "React",
    //     "Nextjs",
    //     "Typescript",
    //     "Javascript",
    //     "Vercel",
    //     "Jest",
    //     "Zod",
    //     "Tailwind",
    //     "ESLint",
    //     "Prettier",
    //     "AWS",
    //     "Postgres",
    //   ],
    //   demo_link: "https://www.william.travel/",
    //   github_link: "https://github.com/WJB3003/next.itinerary.index",
    // },
    // {
    //   name: "Remote Play",
    //   description:
    //     "Similar to Jackbox or Kahoot, Remote Play allows users to play games remotely with others.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: [
    //     "Java",
    //     "Spring Boot",
    //     "Vue",
    //     "Typescript",
    //     "Javascript",
    //     "Jest",
    //     "Tailwind",
    //     "ESLint",
    //     "Prettier",
    //     "AWS",
    //   ],
    //   mermaid: ``,
    //   demo_link: "",
    //   github_link: "",
    // },
    // {
    //   name: "React Boilerplate",
    //   description:
    //     "React boilerplate that creates good starting point for new projects.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: [
    //     "React",
    //     "Typescript",
    //     "Javascript",
    //     "Jest",
    //     "Zod",
    //     "Tailwind",
    //     "ESLint",
    //     "Prettier",
    //     "AWS",
    //     "Postgres",
    //   ],
    //   mermaid: ``,
    //   demo_link: "",
    //   github_link: "",
    // },
    // {
    //   name: "NextJS Boilerplate",
    //   description:
    //     "NextJS boilerplate that creates good starting point for new projects.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: [
    //     "NextJS",
    //     "Typescript",
    //     "Javascript",
    //     "Jest",
    //     "Zod",
    //     "Tailwind",
    //     "ESLint",
    //     "Prettier",
    //     "AWS",
    //     "Postgres",
    //   ],
    //   mermaid: ``,
    //   demo_link: "",
    //   github_link: "",
    // },
    // {
    //   name: "PHP Laravel API Boilerplate",
    //   description:
    //     "PHP Boilerplate that creates good starting point for APIs including basic CRUD operations.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: ["PHP", "Laravel"],
    //   mermaid: ``,
    //   demo_link: "",
    //   github_link: "",
    // },
    // {
    //   name: "React Reusable Components",
    //   description:
    //     "Generic reusable components that can be used in any React project.",
    //   image:
    //     "https://static.vecteezy.com/system/resources/thumbnails/022/963/918/small_2x/ai-generative-cute-cat-isolated-on-solid-background-photo.jpg",
    //   tech: [
    //     "React",
    //     "Typescript",
    //     "Javascript",
    //     "Jest",
    //     "Tailwind",
    //     "ESLint",
    //     "Prettier",
    //   ],
    //   mermaid: ``,
    //   demo_link: "",
    //   github_link: "",
    // },
  ];

  return (
    <div className="max-w-full my-24 mx-8 overflow-hidden">
      <div className="gradient left-[33%] md:left-[300px] sm:left-[-50px] top-[33%] bg-[orange]" />
      <div className="gradient2 right-[33%] md:right-[300px] sm:right-[-50px] top-[23%] bg-[red]" />
      <div className="grid gap-16">
        <h1 className="text-5xl font-bold">My Work</h1>
        {(projects || []).map((project) => (
          <div
            className="flex sm:block sm:content-center gap-8 w-full justify-center"
            key={project.name}
          >
            <div className="max-w-[50%] sm:w-full">
              <div className="max-h-[300px] aspect-video overflow-hidden sm:w-[100vw]">
                <div className="flex">
                  <img
                    className="max-h-[300px] rounded aspect-video object-cover"
                    alt={`project-${project.name}`}
                    src={project.image}
                  />
                </div>
              </div>
            </div>
            <div className="grid gap-8 max-w-[50%] sm:w-full grow">
              <div className="flex flex-col justify-between sm:w-[100vw]">
                <div>
                  <a
                    href={`/project/${project.name}`}
                    className="text-3xl font-bold hover:underline"
                  >
                    {project.name}
                  </a>
                  <div className="mb-3 text-wrap">{project.description}</div>
                </div>
                <div>
                  <div className="text-xl font-bold">Technologies Used</div>
                  <div className="flex gap-2 flex-wrap">
                    {project.tech.map((tech) => (
                      <div key={tech}>{tech}</div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-wrap gap-4 pt-6 pb-3">
                  {project?.demo_link && (
                    <a
                      href={project.demo_link}
                      className="flex gap-3 group items-center"
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        className="text-4xl sm:text-2xl group-hover:text-[darkblue] drop-shadow-xl"
                      />
                      <div className="text-xl content-center group-hover:text-[lightgray]">
                        Demo
                      </div>
                    </a>
                  )}
                  {project.github_link && (
                    <a
                      href={project.github_link}
                      className="flex gap-3 group items-center"
                    >
                      <FontAwesomeIcon
                        icon={faGithub}
                        className="text-4xl sm:text-2xl group-hover:text-[darkblue] drop-shadow-xl"
                      />
                      <div className="text-xl content-center group-hover:text-[lightgray]">
                        Github
                      </div>
                    </a>
                  )}
                </div>
              </div>
              {/* {project.mermaid && (
                <div>
                  <Mermaid chart={project.mermaid} />
                </div>
              )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
