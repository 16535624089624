export default function Resume() {
  return (
    <div className="h-[100vh]">
      <iframe
        src="/resume.pdf"
        title="William Brown's Resume"
        width="100%"
        height="100%"
      />
    </div>
  );
}
