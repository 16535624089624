import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Mermaid from "../Mermaid";

export default function Project(props) {
  const [activeTab, setActiveTab] = React.useState();
  const project = {
    name: "Travel Itinerary Web Application",
    description: `
      The Travel Itinerary Web Application is an AI-assisted planner designed to help users organize their trips by offering recommendations on places to visit, eat, and stay. The application utilizes a variety of technologies including Typescript, Javascript, React, PostgreSQL, Github, AWS, React Native, CSS, Jest, and PHP. The structure of the application involves several components: a PostgreSQL database, a PHP API, AWS S3 for storage, Gemini AI for recommendations, and Firebase for authentication. The frontend is built with React and deployed on Vercel, while the mobile app is developed with React Native and deployed via Firebase.
      The database schema includes relationships between trips, activities, images, locations, and users. The application features a user-friendly interface with a home screen image and a detailed list of technologies used. The project is accessible online at william.travel and the source code is available on GitHub at next.itinerary.index.
      The code snippet provided shows the rendering of the project details, including the project name, description, and a list of technologies with their respective icons. The layout is styled using Tailwind CSS classes for a responsive and visually appealing design.
    `,
    image: "/projects/william-travel-home-screen.png",
    tech: [
      {
        name: "Typescript",
        icon: "/tech/icon/typescript.png",
      },
      {
        name: "Javascript",
        icon: "/tech/icon/javascript.png",
      },
      {
        name: "React",
        icon: "/tech/icon/react.png",
      },
      {
        name: "PostgreSQL",
        icon: "/tech/icon/postgresql.png",
      },
      {
        name: "Github",
        icon: "/tech/icon/github.png",
      },
      {
        name: "AWS",
        icon: "/tech/icon/aws.png",
      },
      {
        name: "React Native",
        icon: "/tech/icon/react-native.png",
      },
      {
        name: "CSS",
        icon: "/tech/icon/css.png",
      },
      {
        name: "Jest",
        icon: "/tech/icon/jest.png",
      },
      {
        name: "PHP",
        icon: "/tech/icon/php.png",
      },
      // "Vercel",
      // "Zod",
      // "Tailwind",
      // "ESLint",
      // "Prettier",
    ],
    structure: `
      flowchart TD
        Z[(Postgres)] -->|RedBeam| Y[PHP API]
        X[AWS S3 Bucket] -->|Cloudfront| Y
        W[Gemini AI] --> Y
        K[Firebase Auth] --> Y
        Y --> V[React]
        V -->|Vercel| T[william.travel]
        Y --> U[React Native]
        U -->|Firebase Deploy| S[William Travel Mobile App]
        R[User Input] -->|Zod| S
        R[User Input] -->|Zod| T
    `,
    database_relationships: `
      erDiagram
        Trip ||--o{ Activity : "TripActivity"
        Trip ||--o{ Image : "TripImage"
        Trip ||--o| Location : "TripLocation"
        Trip }o--o{ User : "TripUser"
        Activity ||--o| Location : "ActivityLocation"
        Activity ||--o{ Image : "ActivityImage"
    `,
    demo_link: "https://www.william.travel/",
    github_link: "https://github.com/WJB3003/next.itinerary.index",
  };

  return (
    <div className="mx-auto max-w-7xl px-[7.5vw] 2xl:px-0 pb-8 min-h-screen mt-24 flex gap-6">
      <div className="sm:hidden md:hidden w-[25vw] gap-3">
        <div className="absolute sticky flex flex-col top-32 gap-2">
          {[
            {
              href: "",
              name: "Overview",
            },
            {
              href: "technologies",
              name: "Technologies",
            },
            {
              href: "structure",
              name: "Project Structure",
            },
            {
              href: "db-relationships",
              name: "Database Relationships",
            },
            {
              href: "db-tables",
              name: "Database Tables",
            },
            {
              href: "api-endpoints",
              name: "API Endpoints",
            },
          ].map((navItem) => (
            <a
              key={navItem.href}
              href={`#${navItem.href}`}
              onClick={() => setActiveTab(navItem.href)}
              className={`
                ${
                  activeTab === navItem.href
                    ? "text-[darkorange] border-l-2 border-[red] pl-2"
                    : "hover:text-[darkorange] hover:border-l-2 hover:border-[red] hover:pl-2"
                } transition-all
              `}
            >
              {navItem.name}
            </a>
          ))}
        </div>
      </div>
      <div className="w-[75vw] sm:w-full md:w-full">
        <img
          src="/projects/william-travel-home-screen.png"
          alt="project-image"
          className="self-center justify-center mx-auto w-full rounded"
        />
        <div>
          <div className="text-xl font-bold mt-8 mb-2">{project.name}</div>
          <div>{project.description}</div>
        </div>
        <div id="technologies">
          <div className="text-xl font-bold mt-8 mb-4">Technologies</div>
          <div className="flex flex-wrap gap-8">
            {(project.tech ?? []).map((tech) => (
              <div key={tech.icon} className="flex flex-col items-center gap-2">
                <img alt={tech.name} src={tech.icon} height={56} width={56} />
                <div className="font-bold">{tech.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div id="structure">
          <div className="text-xl font-bold mt-8 mb-4">Project Structure</div>
          <Mermaid
            className="w-full flex justify-center"
            chart={project.structure}
          />
        </div>
        <div id="db-relationships">
          <div className="text-xl font-bold mt-8 mb-4">
            Database Relationships
          </div>
          <Mermaid
            className="w-full flex justify-center"
            chart={project.database_relationships}
          />
        </div>
        <div id="db-tables">
          <div className="text-xl font-bold mt-8 mb-4">Database Tables</div>
          <Mermaid
            className="w-full flex justify-center"
            chart={project.database_relationships}
          />
        </div>
        <div id="api-endpoints">
          <div className="text-xl font-bold mt-8 mb-4">API Endpoints</div>
          <div className="bg-[white] rounded-xl pt-2">
            <SwaggerUI url="/projects/endpoints.yml" />
          </div>
        </div>
      </div>
      <div className="gradient left-[33%] md:left-[300px] sm:left-[-50px] top-[33%] bg-[orange]" />
      <div className="gradient2 right-[33%] md:right-[300px] sm:right-[-50px] top-[23%] bg-[red]" />
    </div>
  );
}
